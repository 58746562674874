import(/* webpackMode: "eager" */ "/vercel/path0/components/container/container.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/type/v3.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/spacer/spacer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/componentsNew/developer/subnav.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/pages/developer-old/developer.module.scss");
